import Vue from "vue";
import App from "./App.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import routes from "./routes";
import VueRouter from "vue-router";
import JSONView from 'vue-json-component';
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import { veLoading } from "vue-easytable";
import { BFormFile } from 'bootstrap-vue'

import VueWordCloud from 'vuewordcloud';
 
Vue.component(VueWordCloud.name, VueWordCloud);


Vue.component('b-form-file', BFormFile)
Vue.prototype.$veLoading = veLoading;
Vue.use(VueEasytable);
Vue.use(JSONView);
Vue.use(VueRouter);
const router = new VueRouter({
  routes
});
Vue.use(VueAxios, axios);
import { state as store_state, actions as store_actions } from "./store";
const state = Vue.observable(store_state)
const actions = Vue.observable(store_actions)
const store = { state: state, actions: actions }
Vue.prototype.$store = store
import VueFetch from 'vue-fetch'
import Vuelidate from "vuelidate";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  FormGroupPlugin,
  FormPlugin,
  FormInputPlugin,
  ButtonPlugin,
  CardPlugin,
  NavbarPlugin,
  FormSelectPlugin,
  AlertPlugin,
  ToastPlugin,
  LayoutPlugin, 
  InputGroupPlugin,
  TablePlugin,
  FormTextareaPlugin,
  SidebarPlugin,
  ModalPlugin,
  FormDatepickerPlugin,
  IconsPlugin,
  ListGroupPlugin 
} from "bootstrap-vue";
[
  FormGroupPlugin,
  FormPlugin,
  FormInputPlugin,
  ButtonPlugin,
  CardPlugin,
  NavbarPlugin,
  FormSelectPlugin,
  AlertPlugin,
  ToastPlugin,
  LayoutPlugin, 
  InputGroupPlugin,
  TablePlugin,
  FormTextareaPlugin,
  SidebarPlugin,
  ModalPlugin ,
  FormDatepickerPlugin,
  IconsPlugin,
  ListGroupPlugin 
].forEach((x) => Vue.use(x));
Vue.use(Vuelidate,VueFetch);


import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

// const chart = new Chart(ctx, {
//   type: 'line',
//   data: data,
//   options: {
//     onClick: (e) => {
//       const canvasPosition = getRelativePosition(e, chart);

//       // Substitute the appropriate scale IDs
//       const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
//       const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
//     }
//   }
// });


axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

const shared_data = {
  username: localStorage.username,
  permission: localStorage.permission,
  login(username, permission) {
    localStorage.setItem("username", username);
    localStorage.setItem("permission", permission);
    this.username = username;
    this.permission = permission;
  },
  logout() {
    localStorage.removeItem("username");
    localStorage.removeItem("permission");
    this.username = undefined;
    this.permission = undefined;
  }
};
// Vue.prototype.$root.store = shared_data;

new Vue({
  router,
  data() {
    return {
      store: shared_data,
      chart: Chart,
      defaultWordsRoot :[]
    };
  },
  methods: {
    toast(title, content, variant = null, append = false) {
      this.$bvToast.toast(`${content}`, {
        title: `${title}`,
        toaster: "b-toaster-top-center",
        variant: variant,
        solid: true,
        appendToast: append,
        autoHideDelay: 3000
      });
    }
    
  },
  mounted() {
    // this.$router.push("/").catch(() => {
    //   this.$forceUpdate();
    // });
  },
  render: (h) => h(App)
}).$mount("#app");



export {router}