
const state = {
    username:"",
    permission: -1,
    server_domain: "https://cytox.cs.bgu.ac.il/",
    config : { headers: { 'content-type':'text/plain'}},
    scanners:[]
}

const actions = {

}

export {state, actions}