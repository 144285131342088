import NotFound from "./pages/NotFoundPage";


const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("./pages/WelcomePage")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/LoginPage")
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("./pages/AdminPage")
  },
  {
    path: "/search",
    name: "search",
    component: () => import("./pages/MainPage")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./pages/RegisterPage")
  },
  {
    path: "/result",
    name: "result",
    component: () => import("./pages/ResultPage")
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("./pages/ForgotPasswordPage")
  },
  
  {
    path: "/reset_password/:token",
    name: "reset_password",
    component: () => import("./pages/Reset")
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound
  }
];

export default routes;